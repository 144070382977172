.header {
    display: flex;
    justify-content: space-between; /* Adjusted from center to space-between */
    align-items: center;
    padding: 0.7em 1em; /* Add horizontal padding here */
    position: relative;
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px; 
    z-index: 1000;
    border-radius: 20px;
    /* flex-wrap: wrap; */
    background: linear-gradient(135deg, #1afbf07d, #d900ff5d);
    height: 40px;
    outline: none;
    box-shadow: 0 8px 15px -4px rgba(0, 0, 0, 0.2); /* Adjust the color and opacity as needed */
  }
  
  
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure the content inside also justifies with space between */
    padding-left: 10px; /* Optional: for better spacing inside the container */
    padding-right: 10px; /* Optional: for better spacing inside the container */
    width: 100%; /* Set the width to take full width of the header */
    margin-left: 80px;
    margin-right: 80px;
  }
  
   .header a {
    padding: 0.5em 1em;
  }

  
  .navigation{
    width: 24em;
    margin-right: auto;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    margin-left: auto;
  }
  
  /* Align navigation links horizontally */
  .navigation a {
    margin: 0 0.5em;
  }
  
  .header a, 
  .header button {
    margin: auto 0; 
  }
  
  /* Glitter Logo styles */
  .logo {
      text-decoration: none;          /* Remove underline from links */
      font-size: 20px;
      color: black;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      border: none;
      background: none;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: 400ms;
      transition-property: color;
      font-variation-settings: 'wght' 700, 'wdth' 100; /* Adjust the values (100 to 900) for different weight and width */
  }
    
  /* .logo:focus, .logo:hover {
    box-shadow: #ffffff7d 0px 8px 10px;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
  }
    
  .logo:focus:after, .logo:hover:after {
      width: 100%;
      left: 0%;
  }
  
  .logo:after {
    content: "";
    pointer-events: none;
    bottom: 1px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: rgb(255, 255, 255);
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
  } */
    
  
   /* About Us Navigation styles */
  .about-link {
      text-decoration: none;          /* Remove underline from links */
      color: rgb(0, 0, 0);   
      font-size: 1em;               /* Increase the text size. Adjust as needed. */
      line-height: 1.2;               /* Link color */
      margin: 0 10px;  
      position: relative;
      border: none;
      background: none;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: 400ms;
      transition-property: color; 
      font-variation-settings: 'wght' 700, 'wdth' 100; /* Adjust the values (100 to 900) for different weight and width */
  }
    
  .about-link:focus, .about-link:hover {
      /* color: black; */
      box-shadow: #ffffff7d 0px 8px 10px;
      transition: box-shadow 0.3s ease, transform 0.2s ease;
  }
    
  .about-link:focus:after, .about-link:hover:after {
      width: 100%;
      left: 0%;
  }
  
  .about-link:after {
    content: "";
    pointer-events: none;
    bottom: 1px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: rgb(255, 255, 255);
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
  }
  
  
  /* Twitter Navigation styles */
  .twitter-link {
    text-decoration: none;          /* Remove underline from links */
    color: rgb(0, 0, 0);   
    font-size: 1em;               /* Increase the text size. Adjust as needed. */
    line-height: 1.2;               /* Link color */
    margin: 0 10px;   
    position: relative;
    border: none;
    background: none;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color; 
    font-variation-settings: 'wght' 700, 'wdth' 100; /* Adjust the values (100 to 900) for different weight and width */
  }
  
  .twitter-link:focus, .twitter-link:hover {
    box-shadow: #ffffff7d 0px 8px 10px;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
  }
  
  .twitter-link:focus:after, .twitter-link:hover:after {
    width: 100%;
    left: 0%;
  }
  
  .twitter-link:after {
    content: "";
    pointer-events: none;
    bottom: 1px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: #ffffff;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
  }
  
  
  /* Linkedin Navigation styles */
  .linkedin-link {
    text-decoration: none;          /* Remove underline from links */
    color: hsl(0, 0%, 0%);   
    font-size: 1em;               /* Increase the text size. Adjust as needed. */
    line-height: 1.2;               /* Link color */
    margin: 0 10px;   
    margin-right: 1em; /* This adds space around each link in the navigation */
    position: relative;
    border: none;
    background: none;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color; 
    font-variation-settings: 'wght' 700, 'wdth' 100; /* Adjust the values (100 to 900) for different weight and width */
  }
  
  .linkedin-link:focus, .linkedin-link:hover {
    /* color: RGB(114, 137, 217); */
    box-shadow: #ffffff7d 0px 8px 10px;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
  }
  
  .linkedin-link:focus:after, .linkedin-link:hover:after {
    width: 100%;
    left: 0%;
  }
  
  .linkedin-link:after {
    content: "";
    pointer-events: none;
    bottom: 1px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    /* background-color: RGB(114, 137, 217); */
    background-color: #ffffff;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
  }

  button {
    cursor: pointer;
    font-weight: 700;
    font-family: Anybody,"sans-serif";
    transition: all .2s;
    padding: 3px 20px;
    border-radius: 100px;
    background: #cfef00;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  
  button:hover {
    background: #c4e201;
  }
  
  button > svg {
    width: 34px;
    margin-left: 10px;
    transition: transform .3s ease-in-out;
  }
  
  button:hover svg {
    transform: translateX(5px);
  }
  
  button:active {
    transform: scale(0.95);
  }
  
  