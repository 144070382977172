.container {
    display: flex;
    flex-direction: column; /* This will stack children vertically */
    justify-content: flex-start; /* This aligns children to the start of the cross axis */
    align-items: center; 
    width: 100%; /* It's good to set a width to contain children within */
  }
  
  .filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: rgb(255, 255, 255);
    border-radius: 20px;
    margin-top: 50px;
    margin-bottom: 20px; /* Add margin-bottom to create space between the filter bar and the table */
    width: 80%;
    box-shadow: 10px 10px 5px 0 rgba(157, 217, 255, 0.5);
    gap: 5px; /* This will apply to all child elements */
  }
  
  /* Consistent styling for all input fields and the select dropdown */
  .filter-bar input[type="text"],
  .filter-bar input[type="number"],
  .filter-bar input[type="date"],
  .filter-bar select { /* Add select to the common styles */
    border: 2px solid #ccc;
    border-radius: 15px;
    padding: 10px;
    outline: none;
  }
  
  .filter-bar input::placeholder {
    color: #aaa;
  }

  .filter-bar input[type="number"],
.filter-bar select {
  margin-right: 2px; /* Adjust the right margin to reduce space between price and select condition */
}

  
  /* Style for the select dropdown */
  .filter-bar select {
    appearance: none; /* Remove default style on some browsers */
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    background: url('data:image/svg+xml;utf8,<svg ...></svg>') no-repeat right; /* Add custom arrow icon */
    cursor: pointer;
  }
  
  .filter-bar button {
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    font-weight: bold;
    white-space: nowrap; /* Prevents text wrapping in buttons */
  }
  
  .filter-bar button:hover {
    background-color: #333;
  }
  
  /* Optional: Add extra padding at the bottom of the container to ensure no overlap with footer */
  .body-content {
    padding-bottom: 60px; /* Adjust based on the height of your footer */
  }
 
  #result {
    border: 1px dotted #ccc;
    padding: 3px;
  }
  #result ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  #result ul li {
    padding: 5px 0;
  }
  #result ul li:hover {
    background: #eee;
  }